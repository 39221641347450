import { GridProps } from "@/types/components/Grid";
import { forwardRef } from "react";

const Row = forwardRef<HTMLDivElement, GridProps>(({ children, className = '', ...props }, ref) => {
    return (
        <div className={`row flex flex-wrap maxMd:-mx-4 -mx-3 ${className}`} ref={ref}>
            {children}
        </div>
    );
});

export default Row;