import { GridProps } from "@/types/components/Grid"

const Column = ({ xxl, xl = 12, lg = 12, md = 12, sm = 12, xs = 12, qhd = 0, ultraLarge = 0, children, className = '' }: GridProps) => {
    const sizeVariant4k: GridProps = {
        0: '',
        1: '4k:w-1/12',
        2: '4k:w-2/12',
        3: '4k:w-3/12',
        4: '4k:w-4/12',
        5: '4k:w-5/12',
        6: '4k:w-6/12',
        7: '4k:w-7/12',
        8: '4k:w-8/12',
        9: '4k:w-9/12',
        10: '4k:w-10/12',
        11: '4k:w-11/12',
        12: '4k:w-full',
    }
    const sizeVariantQhd: GridProps = {
        0: '',
        1: 'qhd:w-1/12',
        2: 'qhd:w-2/12',
        3: 'qhd:w-3/12',
        4: 'qhd:w-4/12',
        5: 'qhd:w-5/12',
        6: 'qhd:w-6/12',
        7: 'qhd:w-7/12',
        8: 'qhd:w-8/12',
        9: 'qhd:w-9/12',
        10: 'qhd:w-10/12',
        11: 'qhd:w-11/12',
        12: 'qhd:w-full',
    }
    const sizeVariantXXl: GridProps = {
        1: '2xl:w-1/12',
        2: '2xl:w-2/12',
        3: '2xl:w-3/12',
        4: '2xl:w-4/12',
        5: '2xl:w-5/12',
        6: '2xl:w-6/12',
        7: '2xl:w-7/12',
        8: '2xl:w-8/12',
        9: '2xl:w-9/12',
        10: '2xl:w-10/12',
        11: '2xl:w-11/12',
        12: '2xl:w-full',
    }
    const sizeVariantXl: GridProps = {
        1: 'xl:w-1/12',
        2: 'xl:w-2/12',
        3: 'xl:w-3/12',
        4: 'xl:w-4/12',
        5: 'xl:w-5/12',
        6: 'xl:w-6/12',
        7: 'xl:w-7/12',
        8: 'xl:w-8/12',
        9: 'xl:w-9/12',
        10: 'xl:w-10/12',
        11: 'xl:w-11/12',
        12: 'xl:w-full',
    }
    const sizeVariantLg: GridProps = {
        1: 'lg:w-1/12',
        2: 'lg:w-2/12',
        3: 'lg:w-3/12',
        4: 'lg:w-4/12',
        5: 'lg:w-5/12',
        6: 'lg:w-6/12',
        7: 'lg:w-7/12',
        8: 'lg:w-8/12',
        9: 'lg:w-9/12',
        10: 'lg:w-10/12',
        11: 'lg:w-11/12',
        12: 'lg:w-full',
    }
    const sizeVariantMd: GridProps = {
        1: 'md:w-1/12',
        2: 'md:w-2/12',
        3: 'md:w-3/12',
        4: 'md:w-4/12',
        5: 'md:w-5/12',
        6: 'md:w-6/12',
        7: 'md:w-7/12',
        8: 'md:w-8/12',
        9: 'md:w-9/12',
        10: 'md:w-10/12',
        11: 'md:w-11/12',
        12: 'md:w-full',
    }
    const sizeVariantSm: GridProps = {
        1: 'sm:w-1/12',
        2: 'sm:w-2/12',
        3: 'sm:w-3/12',
        4: 'sm:w-4/12',
        5: 'sm:w-5/12',
        6: 'sm:w-6/12',
        7: 'sm:w-7/12',
        8: 'sm:w-8/12',
        9: 'sm:w-9/12',
        10: 'sm:w-10/12',
        11: 'sm:w-11/12',
        12: 'sm:w-full',
    }
    const sizeVariantXs: GridProps = {
        1: 'maxMd:w-1/12',
        2: 'maxMd:w-2/12',
        3: 'maxMd:w-3/12',
        4: 'maxMd:w-4/12',
        5: 'maxMd:w-5/12',
        6: 'maxMd:w-6/12',
        7: 'maxMd:w-7/12',
        8: 'maxMd:w-8/12',
        9: 'maxMd:w-9/12',
        10: 'maxMd:w-10/12',
        11: 'maxMd:w-11/12',
        12: 'maxMd:w-full',
    }
    
    return (
        <div className={`column maxMd:px-4 px-3 ${ultraLarge && sizeVariant4k[ultraLarge]} ${qhd && sizeVariantQhd[qhd]} ${xxl && sizeVariantXXl[xxl]} ${sizeVariantXl[xl]} ${sizeVariantLg[lg]} ${sizeVariantMd[md]} ${sizeVariantXs[xs]} ${sizeVariantSm[sm]} w-full ${className}`}>
            {children}
        </div>
    );
}
export default Column;