import { GridProps } from "@/types/components/Grid";

const Container = ({ full = false, size = "default", className = '', children }: GridProps) => {
    return (
        <div className={`maxMd:px-4 px-3 ${full ? 'w-full' : 'maxMd:max-w-full max-w-[calc(100%_-_96px)] w-full mx-auto' } ${className}`}>
            {children}
        </div>
    )
}

export default Container;